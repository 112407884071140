<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Lector de códigos de barra</h1>

    <StreamBarcodeReader @decode="onDecode"></StreamBarcodeReader>

    <div class="barcode-result">
      <p>Código de barra: {{ text }}</p>
    </div>
  </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import { EventBus } from "@/event-bus";

// @ is an alias to /src
export default {
  name: "BarcodeReaderModal",
  props: [],
  components: {
    StreamBarcodeReader,
  },
  data() {
    return {
      text: "",
      id: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     *
     * @desc sends the barcode information to the parent component that called this component
     */
    onDecode(a, b, c) {
      this.text = a;

      if (this.id) clearTimeout(this.id);
      this.id = setTimeout(() => {
        if (this.text === a) {
          this.text = "";
        }
      }, 5000);

      if (this.text) {
        EventBus.$emit("barcodeRead", {
          code: this.text,
        });
        this.closeModal();
      }
    },
  },
  computed: {
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };
        return clientWithProps;
      }).filter((singleClient) => {
        return singleClient.TIPO === "CL";
      });
    },
  },
};
</script>

<style>
@import "../../Global/style/Global.css";

.scanner-container {
  max-height: 32rem !important;
  height: 32rem !important;
}

.scanner-container div:first-child {
  max-height: 31.5rem !important;
  height: 31.5rem !important;
}

.scanner-container video {
  width: 70% !important;
  max-width: 70% !important;
  margin-left: 15% !important;
  margin-right: 15% !important;
}

.scanner-container .overlay-element {
  width: 70% !important;
  max-width: 70% !important;
  margin-left: 15% !important;
  margin-right: 15% !important;
}

.barcode-result {
  margin-top: 1.5rem;
  text-align: center;
  font-size: 1.15rem;
}

.scanner-container .laser {
  width: 40% !important;
  margin-left: 30% !important;
}
</style>
